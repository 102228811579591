import * as React from "react";
import styled from "styled-components";

const TheFooter = styled.footer`
  width: 100%;
  height: 366px;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 450px) {
    height: 240px;    
  }


  & h1 {
    font-family: serif;
    font-weight: 900;
    font-size: 32px;
    color: #ffd682;
    letter-spacing: 9.3px;
    text-align: center;
    margin-left: 75px;
    margin-right: 75px;

    @media (max-width: 450px) {
      font-size: 24px;
      margin-left: 25px;
      margin-right: 25px;

    }
  }
  & p {
    font-size: 27px;
    color: #ffd682;
    letter-spacing: 1.35px;
    text-align: center;
    margin-left: 75px;
    margin-right: 75px;

    @media (max-width: 450px) {
      font-size: 18px;
      margin-left: 25px;
      margin-right: 25px;
    }

  }
`;

const Footer = () => {
  return (
    <TheFooter>
      <h1>源润控股集团股份有限公司</h1>
      <p>天津市滨海新区⻩海路159号</p>
    </TheFooter>
  );
};

export default Footer;
