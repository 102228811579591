import * as React from "react"
import styled from "styled-components";

import HeroVideoBlock from "../components/hero_video_block"
import HomePageSec2 from "../components/home_page_sec-2"

import loop_earth_connection_mp4 from "../assets/videos/loop_earth_connection.mp4"


const Main = styled.main`
  display: flex;
  flex-direction: column;
`

// markup
const EarthConnect = () => {
  return (
    <Main>
      {/* <HeroVideoBlock img_src={glow_logo_png} video_src={loop_earth_connection_mp4}></HeroVideoBlock> */}
      <HeroVideoBlock video_src={loop_earth_connection_mp4}></HeroVideoBlock>
      <HomePageSec2></HomePageSec2>
    </Main>
  )
}

export default EarthConnect

