import * as React from 'react'
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// const Div = styled.div`
//     position: fixed;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     float: left;
//     width: 100%;
//     overflow: hidden;
//     z-index: -10;
// `
const Header = styled.header`
    position: relative;
    height: 100vh;
    /* text-align: center; */
    display: flex;
    align-items: center;
    justify-content: center;
`
const VideoFilter =  styled.div`
    height: 100vh;
    width: 100vw;
    background: rgba(0,0,0, 0.2);
    z-index: -1;
    position: fixed;
`
const Img = styled.img`
        @media (min-width: 1201px) {
        /* top: calc(50% - 250px);
        left: calc(50% - 250px); */
        width: 600px;
    }

    @media (max-width: 1200px) {
        /* top: calc(50% - 250px);
        left: calc(50% - 250px); */
        width: 500px;
    }
    @media (max-width: 1000px) {
        /* top: calc(50% - 200px);
        left: calc(50% - 200px); */
        width: 400px;
    }
    @media (max-width: 400px) {
        /* top: calc(50% - 150px);
        left: calc(50% - 150px); */
        width: 300px;
    }


`
// const Video = styled.video`
//     position: fixed;
//     top: 50%;
//     left: 50%;
//     min-width: 100%;
//     min-height: 100%;
//     width: auto;
//     height: auto;
//     z-index: -100;
//     -ms-transform: translateX(-50%) translateY(-50%);
//     -moz-transform: translateX(-50%) translateY(-50%);
//     -webkit-transform: translateX(-50%) translateY(-50%);
//     transform: translateX(-50%) translateY(-50%);
//     background: url(polina.jpg) no-repeat;
//     background-size: cover; 
// `

const Video = styled.video`
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
`

const StyledGatsbyImage = styled(GatsbyImage)`
    img{
        @media (min-width: 1201px) {
        /* top: calc(50% - 250px);
        left: calc(50% - 250px); */
        width: 600px;
    }

    @media (max-width: 1200px) {
        /* top: calc(50% - 250px);
        left: calc(50% - 250px); */
        width: 500px;
    }
    @media (max-width: 1000px) {
        /* top: calc(50% - 200px);
        left: calc(50% - 200px); */
        width: 400px;
    }
    @media (max-width: 400px) {
        /* top: calc(50% - 150px);
        left: calc(50% - 150px); */
        width: 300px;
    }
}

`


const HeroVideoBlock = (props) => {
    const data = useStaticQuery(graphql`
        query {
        file(relativePath: {eq: "logo@3x.png"}) {
            childImageSharp {
            gatsbyImageData(width:500)
            }
        }
        }
    `)
    const image = getImage(data.file)

    return (
        <>
            
            <Video src={props.video_src} autoPlay loop muted playsInline></Video>
            <VideoFilter></VideoFilter>
            <Header>
                {/* <Img src={props.img_src} alt="this is glow_logo_png"></Img> */}
                {/* <StyledGatsbyImage image={image} alt="this is glow_logo_png"></StyledGatsbyImage> */}
                <GatsbyImage image={image} alt="this is glow_logo_png"></GatsbyImage>
            </Header>
        </>
    )
}

export default HeroVideoBlock