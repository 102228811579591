import * as React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Footer from "./footer";

const Main = styled.main`
  height: auto;
  /* margin-top: 100vh; */
  /* background-color: red; */
  background: rgba(0, 0, 0, 0.2);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* backdrop-filter: saturate(180%) blur(20px); */
  /* -webkit-backdrop-filter: saturate(180%) blur(20px); */
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
`;

const Para = styled.p`
  max-width: 1050px;
  margin: 18px 75px 61px 75px;
  text-align: center;
  color: whitesmoke;
  line-height: 1.4;
  letter-spacing: 0.03em;
  /* font-weight: 600; */
  /* text-shadow: 0 0 20px rgba(242,211,147, 1); */
  /* text-shadow: 0 0 20px orange; */
  @media (max-width: 450px) {
      font-size: 18px;
      margin-left: 25px;
      margin-right: 25px;
    }

`;

const ImgWrapper = styled.div`
  margin: 0 50px;
`;

const Spacer = styled.div<{ customHeight: string }>`
  width: 100%;
  height: ${(props) => props.customHeight || "1rem"};
`;

// data
const content = [
  {
    text: "源润控股集团股份有限公司成立于1993年12月18日，前身是天津华泰控股集团股份有限公司。",
    pic: "huatai_to_yuanrun_blue",
    pic_width: 540,
  },
  {
    text: "公司涉足房产、旅游、金融、保险、科技装备等领域。",
    pic: "five_business",
    pic_width: 574,
  },
  {
    text: "集团持有津滨发展（股票代码:000897），为其第二大流通股东；主营业务为房地产，公司持有享誉国内外的“基辅”航母主题公园。",
    pic: "TEDA_aircraft",
    pic_width: 513,
  },
  {
    text: "参与发起并控股恒安标准人寿保险有限公司，此公司持有5亿股海通证券股票，并控股宁波金港信托投资有限公司。",
    pic: "heng_an",
    pic_width: 520,
  },
  {
    text: "关联公司环渤海控股集团，持有海口职业经济技术学院控股权。发起投资三亚“半山半岛”项目。",
    pic: "huan_bo_hai",
    pic_width: 523,
  },
  {
    text: "投资兼并上海凌云幕墙科技股份有限公司（股票代码900957）。",
    pic: "ling_yun_mu_qiang",
    pic_width: 397,
  },
  {
    text: "投资新疆维特番茄酱厂。",
    pic: "wei_te_fan_qie_jiang",
    pic_width: 498,
  },
  {
    text: "参与发起设立云大科技股份有限公司（股票代码600181），持有云南丽江玉龙大酒店（四星级）。",
    pic: "yun_da_ke_ji",
    pic_width: 496,
  },
  {
    text: "出资成立宁德滨海建设集团。",
    pic: "ning_de_bin_hai",
    pic_width: 492,
  },
  {
    text: "投资兴建陕西榆林凉水井集装站。",
    pic: "liang_shui_jing",
    pic_width: 485,
  },
  {
    text: "投资兴建华宝特种玻璃厂。",
    pic: "hua_bao_te_zhong",
    pic_width: 448,
  },
  {
    text: "直接持有天津保税区销售额及利税前列的汽车贸易公司。",
    pic: "bao_shui_qu",
    pic_width: 501,
  },
  {
    text: "投资收购物流航运有限公司。公司主营国际航运，新近拓展了长沙至德国专用物流航线、香港至拉斯维加斯快速专用航线。自营7架专用货运飞机。",
    pic: "wu_liu_hang_yun",
    pic_width: 498,
  },
  {
    text: "持有河南新县大银尖钼矿。该矿扩编后远景储量约在4万个金属吨。",
    pic: "da_yin_jian",
    pic_width: 374,
  },
  {
    text: "正在筹备并已获批澳门金融特许经营牌照。",
    pic: "jin_rong_te_xu",
    pic_width: 505,
  },
];

const HomePageSec2 = () => {
  // following code can be generated by _archive/home_page_sec-2_generater.py
  const data = useStaticQuery(graphql`
    query {
      huatai_to_yuanrun_blue: file(
        relativePath: { eq: "index_images/huatai_to_yuanrun_blue.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 540)
        }
      }
      five_business: file(
        relativePath: { eq: "index_images/five_business.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 574)
        }
      }
      TEDA_aircraft: file(
        relativePath: { eq: "index_images/TEDA_aircraft.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 513)
        }
      }
      heng_an: file(relativePath: { eq: "index_images/heng_an.png" }) {
        childImageSharp {
          gatsbyImageData(width: 520)
        }
      }
      huan_bo_hai: file(relativePath: { eq: "index_images/huan_bo_hai.png" }) {
        childImageSharp {
          gatsbyImageData(width: 523)
        }
      }
      ling_yun_mu_qiang: file(
        relativePath: { eq: "index_images/ling_yun_mu_qiang.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 397)
        }
      }
      wei_te_fan_qie_jiang: file(
        relativePath: { eq: "index_images/wei_te_fan_qie_jiang.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 498)
        }
      }
      yun_da_ke_ji: file(
        relativePath: { eq: "index_images/yun_da_ke_ji.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 496)
        }
      }
      ning_de_bin_hai: file(
        relativePath: { eq: "index_images/ning_de_bin_hai.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 492)
        }
      }
      liang_shui_jing: file(
        relativePath: { eq: "index_images/liang_shui_jing.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 485)
        }
      }
      hua_bao_te_zhong: file(
        relativePath: { eq: "index_images/hua_bao_te_zhong.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 448)
        }
      }
      bao_shui_qu: file(relativePath: { eq: "index_images/bao_shui_qu.png" }) {
        childImageSharp {
          gatsbyImageData(width: 501)
        }
      }
      wu_liu_hang_yun: file(
        relativePath: { eq: "index_images/wu_liu_hang_yun.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 498)
        }
      }
      da_yin_jian: file(relativePath: { eq: "index_images/da_yin_jian.png" }) {
        childImageSharp {
          gatsbyImageData(width: 374)
        }
      }
      jin_rong_te_xu: file(
        relativePath: { eq: "index_images/jin_rong_te_xu.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 505)
        }
      }
    }
  `);
  const huatai_to_yuanrun_blue = getImage(data.huatai_to_yuanrun_blue);
  const five_business = getImage(data.five_business);
  const TEDA_aircraft = getImage(data.TEDA_aircraft);
  const heng_an = getImage(data.heng_an);
  const huan_bo_hai = getImage(data.huan_bo_hai);
  const ling_yun_mu_qiang = getImage(data.ling_yun_mu_qiang);
  const wei_te_fan_qie_jiang = getImage(data.wei_te_fan_qie_jiang);
  const yun_da_ke_ji = getImage(data.yun_da_ke_ji);
  const ning_de_bin_hai = getImage(data.ning_de_bin_hai);
  const liang_shui_jing = getImage(data.liang_shui_jing);
  const hua_bao_te_zhong = getImage(data.hua_bao_te_zhong);
  const bao_shui_qu = getImage(data.bao_shui_qu);
  const wu_liu_hang_yun = getImage(data.wu_liu_hang_yun);
  const da_yin_jian = getImage(data.da_yin_jian);
  const jin_rong_te_xu = getImage(data.jin_rong_te_xu);

  // above code can be generated by _archive/home_page_sec-2_generater.py

  return (
    <>
    <Main>
      <Spacer customHeight="8rem"></Spacer>
      {content.map((element) => (
        <>
          <ImgWrapper>
            <GatsbyImage
              image={eval(element.pic)}
              alt={`icon ${element.pic}`}
            ></GatsbyImage>
          </ImgWrapper>
          <Para>{element.text}</Para>
        </>
      ))}
      <Spacer customHeight="3rem"></Spacer>
      <Para><strong>回首往昔公司业务星罗棋布，为祖国的大好河山添砖加瓦。瞻望未来源润控股集团一定紧跟中国共产党的步伐，继续为中华民族伟大复兴显肱骨之力。</strong></Para>
      <Spacer customHeight="3rem"></Spacer>
      <Footer></Footer>
    </Main>
    </>
  );
};

export default HomePageSec2;
